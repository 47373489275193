import React, { useEffect, useState } from 'react';
import { Box, Stack, Avatar, Text, HStack, Flex, CloseButton, useDisclosure, Collapse } from '@chakra-ui/react';

import { Message } from '../types';
import { Utils } from '../services';

import Border from './XBorder';
import Button from './XButton';
import { MessageDialog } from './MessageDialog';
import { useAuth } from '../AuthContext';
import { useParams } from 'react-router-dom';
import { useLocation, useSearchParams } from 'react-router-dom';

interface Props {
  message: Message;
  firstInList?: boolean;
  lastInList?: boolean;
  expandable?: boolean;
  onReplyDialogClose?: () => void;
}

const MessageBox: React.FC<Props> = ({ message, firstInList = false, expandable = false, lastInList = false, onReplyDialogClose }) => {
  const { user } = useAuth();
  const { isOpen: isExpanded, onToggle: toggleExpanded } = useDisclosure();
  const { isOpen: isMessageDialogOpen, onToggle: toggleMessageDalogOpen, onClose: onMessageDalogClose } = useDisclosure();
  const { messageId } = useParams();
  
  const [searchparams] = useSearchParams();
  const tab = searchparams.get('tab');
  const { pathname } = useLocation();
  const validPaths = ['/messages', '/messages?tab=received'];
  const handleBoxClick = () => {
    if (expandable) {
      toggleExpanded();
    }
  };

  const handleDialogCLose = () => {
    onMessageDalogClose();

    if (onReplyDialogClose && typeof onReplyDialogClose === 'function') {
      onReplyDialogClose();
    }
  };

  const handleReplyClick = () => {
    toggleMessageDalogOpen();
  };


  const isUnread =
  validPaths.includes(pathname) &&
  (!tab || tab === 'received') &&
  !message.read;

  return (
    <Box pt={3} pb={lastInList ? 3 : 0}>
      <MessageDialog
        recipient={message.sender}
        listingId={message.listing?.id}
        // parentMessageId={message.id}
        parentMessageId={messageId}
        subject={'Re: ' + message.subject}
        isOpen={isMessageDialogOpen}
        onClose={handleDialogCLose}
      />
      {!firstInList && <Border marginBottom={3} />}
      <Stack direction={'row'} gap={3} width={'100%'} className={isUnread ? 'unread' : ''}>
        <Avatar name={message.sender?.fullName} src={message.sender?.profilePhoto} />
        <Box
          cursor={'pointer'}
          onClick={handleBoxClick}
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'space-evenly'}
          width={'100%'}
        >
          <Flex justifyContent={'space-between'}>
            <Box className={isUnread ? 'text' : ''} color={'rgba(0, 0, 0, .5)'} fontSize={'14px'} lineHeight={'normal'}>
              {message.sender?.fullName}
            </Box>
            <Box className={isUnread ? 'text' : ''} fontSize={'12px'} lineHeight={'normal'} color={'#315D4F'}>
              {Utils.formatDate(message.createdAt)}
            </Box>
          </Flex>
          <HStack>
            <Text
              className={isUnread ? 'text' : ''}
              noOfLines={1}
              color="#315D4F"
              fontSize={'21px'}
              fontWeight={700}
              lineHeight={1.2}
              mr={1}
              flexShrink={0}
            >
              {message.subject}{' '}
            </Text>
            {!expandable && (
              <Text noOfLines={1} className={isUnread ? 'text' : ''} color={'rgba(0, 0, 0, .5)'} fontSize={'16px'}>
                {message.message}
              </Text>
            )}
          </HStack>
        </Box>
      </Stack>
      {expandable && (
        <Collapse in={isExpanded} animateOpacity>
          <Box pl={'68px'} pb={2} pt={4}>
            <Text color={'rgba(0, 0, 0, .5)'} fontSize={'16px'}>
              {message.message}
            </Text>
            <Flex justifyContent={'space-between'} pt={6}>
              <Box>
                {message?.sender?.id !== user?.id && (
                  <Button onClick={handleReplyClick} mr={3}>
                    Reply
                  </Button>
                )}
                {/* <Button variant={'delete'}>Delete</Button> */}
              </Box>
              <CloseButton onClick={toggleExpanded} />
            </Flex>
          </Box>
        </Collapse>
      )}
    </Box>
  );
};

export default MessageBox;
