import React from 'react';
import { HStack, Button, Text } from '@chakra-ui/react';

interface PaginationProps {
  currentPage: number;
  totalPages: number;
  onPageChange: (page: number) => void;
}

const ChevronLeftSVG = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15 18L9 12L15 6" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

const ChevronRightSVG = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9 6L15 12L9 18" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

const Pagination: React.FC<PaginationProps> = ({ currentPage, totalPages, onPageChange }) => {
  const getPageNumbers = () => {
    const pages: (number | string)[] = [];
    if (totalPages <= 7) {
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else {
      pages.push(1);
      if (currentPage >= 4) pages.push('...');

      const startPage = Math.max(2, currentPage - 1);
      const endPage = Math.min(totalPages - 1, currentPage + 2);

      for (let i = startPage; i <= endPage; i++) {
        pages.push(i);
      }

      if (currentPage < totalPages - 3) pages.push('...');
      pages.push(totalPages);
    }
    return pages;
  };

  const pages = getPageNumbers();

  return (
    <HStack spacing={2}>
      <Button
        onClick={() => onPageChange(currentPage - 1)}
        isDisabled={currentPage === 1}
        width="40px"
        height="40px"
        borderRadius="full"
        variant="ghost"
        display="flex"
        alignItems="center"
        justifyContent="center"
        marginRight={'-15px'}
      >
        <ChevronLeftSVG />
      </Button>
      {pages.map((page, index) =>
        typeof page === 'number' ? (
          <Button
            key={index}
            onClick={() => onPageChange(page)}
            colorScheme={currentPage === page ? 'green' : 'gray'}
            variant={'solid'}
            width="40px"
            height="40px"
            backgroundColor={currentPage === page ? '#315D4F' : 'white'}
            borderRadius="full"
            fontSize="16px"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            {page}
          </Button>
        ) : (
          <Text key={index} width="40px" textAlign="center">
            {page}
          </Text>
        )
      )}
      <Button
        onClick={() => onPageChange(currentPage + 1)}
        isDisabled={currentPage === totalPages}
        width="40px"
        height="40px"
        borderRadius="full"
        variant="ghost"
        display="flex"
        alignItems="center"
        justifyContent="center"

      >
        <ChevronRightSVG />
      </Button>
    </HStack>
  );
};

export default Pagination;
