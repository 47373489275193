import { Box, Button, Flex, Select, Stack } from '@chakra-ui/react';

interface Props {
  catId?: any;
  subCatId?: any;
  categoryOptions?: any;
  subCategoryOptions?: any;
  onCategoryChange?: any;
  onSubCategoryChange?: any;
  handleApply?: any;
  handleFilterDialogClose?: any;
  handleClear?:any
}

const MarketPlaceFilter = ({
  catId,
  subCatId,
  handleFilterDialogClose,
  categoryOptions,
  subCategoryOptions,
  onCategoryChange,
  onSubCategoryChange,
  handleApply,
  handleClear
}: Props) => {
  return (
    <>
      <Stack direction="column" spacing={4} justify="flex-end" align="center" mt={5}>
        <Select placeholder="Select Category" value={catId} onChange={(e) => onCategoryChange(e.target.value)}>
          {categoryOptions &&
            categoryOptions?.map((category: any) => (
              <option key={category.value} value={category.value}>
                {category.label}
              </option>
            ))}
        </Select>

        <Select
          mt={4}
          placeholder="Select Subcategory"
          value={subCatId}
          onChange={(e) => onSubCategoryChange(e.target.value)}
          isDisabled={!catId}
        >
          {subCategoryOptions &&
            subCategoryOptions?.map((subCategory: any) => (
              <option key={subCategory.value} value={subCategory.value}>
                {subCategory.label}
              </option>
            ))}
        </Select>

        <Flex justify="flex-end" width="100%">
          <Box mr={4}>
            <Button onClick={() =>{
              handleClear()
              handleFilterDialogClose();

            }} type="button">Clear</Button>
          </Box>

          <Button
            onClick={() => {
              handleApply();
              handleFilterDialogClose();
            }}
            type="button"
          >
            Apply
          </Button>
        </Flex>
      </Stack>
    </>
  );
};

export default MarketPlaceFilter;
