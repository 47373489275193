import React, { useState, useEffect } from 'react';
import { Flex, Box, Button, Input, Stack, Text, Select } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import api from '../api';

import { useToastContext } from '../ToastContext';
import { Utils } from '../services';

import { FormField } from './FormField';
import { FieldValidationMessage } from './FieldValidationMessage';

interface Props {
  onSubmit: (data: any) => void;
  subCategory?: any;
  saving: boolean;
}

const schema = yup.object().shape({
  name: yup.string().required(),
  categoryId: yup.string().required(),
});

export const SubCategoryForm: React.FC<Props> = ({ onSubmit, subCategory, saving }) => {
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      ...(!subCategory
        ? {}
        : {
            name: subCategory.name || '',
            categoryId: subCategory.category || '',
          }),
    },
    resolver: yupResolver(schema),
  });

  const toast = useToastContext();
  const [errorMessage, setErrorMessage] = useState('');

  const handleFormSubmit = async (data: any) => {
    try {
      onSubmit({
        ...(subCategory ? { id: subCategory.id } : {}),
        ...data,
      });
    } catch (error: any) {
      let toastMessage = `An error occurred: \n`;

      error.response.data.errors.forEach(({ message }: any) => (toastMessage += '* ' + message + '\n'));

      setErrorMessage(toastMessage);
    }
  };

  const [categoryOptions, setCategoryOptions] = useState([]);
  const fetchData = async () => {
    try {
      const { data }: any = await api.get(`/categories`,{params:{page:1,limit:1000,sort_by:'name'}});

      setCategoryOptions(data.data.map(({ id, name }: any) => ({ label: name, value: id })));

      if (subCategory) {
        setValue('categoryId', subCategory.category.id);
      }
    } catch (error) {
      toast && toast('An error occurred', Utils.formatErrorMessage(error), 'error');
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Box as="form" onSubmit={handleSubmit(handleFormSubmit)}>
      <Stack gap={4} rowGap={1}>
        <Text color="brand.900" fontSize={'md'} mb={4}>
          Choose a Category, type a new Category name, and hit the submit button here.
        </Text>
      </Stack>

      <Stack gap={4} rowGap={1}>
        <FormField
          fieldName={'name'}
          errors={errors}
          control={control}
          render={({ field }: any) => <Input {...field} autoFocus placeholder={'Sub-Category name*'} />}
        />
        <FormField
          fieldName={'categoryId'}
          errors={errors}
          control={control}
          render={({ field }: any) => (
            <Select {...field} placeholder="Category">
              {categoryOptions.map(({ label, value }: any) => (
                <option key={value} value={value}>
                  {label}
                </option>
              ))}
            </Select>
          )}
        />
      </Stack>

      {errorMessage && (
        <FieldValidationMessage mt={-2} pb={6}>
          {errorMessage}
        </FieldValidationMessage>
      )}

      <Flex direction="row" pt={6} justifyContent={'space-between'}>
        <Box />
        <Button type="submit" isLoading={saving} mr={2}>
          Submit
        </Button>
      </Flex>
    </Box>
  );
};

export default SubCategoryForm;
