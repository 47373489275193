import { create } from 'zustand';
import { persist } from 'zustand/middleware';

type MessageState = {
  unreadMessages: any;
  setUnreadMessages: (payload: any) => void;
};

const useMessageStore = create<MessageState>()((set) => ({
  unreadMessages: [], 
  setUnreadMessages: (payload) => set(() => ({ unreadMessages: payload })),
}));

export default useMessageStore;
