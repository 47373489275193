import React, { useEffect, useState } from 'react';
import { Button, Text, useDisclosure } from '@chakra-ui/react';
import { NavLink } from 'react-router-dom';

import api from '../../api';
import { useToastContext } from '../../ToastContext';
import { Utils } from '../../services';
import { ListingType } from '../../constants';

import ModalDialog from '../../components/XModal';
import DataTabs from '../../components/XTabs';
import ListingForm from '../../components/ListingForm';
import { AdminDataTable, Column } from '../../components/AdminDataTable';

const tabData = [
  {
    label: 'Available',
    content: ListingType.Available,
  },
  {
    label: 'Expired',
    content: ListingType.Expired,
  },
];

export const AdminDepartments: React.FC = () => {
  const [data, setData]: any = useState(null);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [filter, setFilter] = useState(tabData[0].content);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);


  const { isOpen: isEditOpen, onOpen: onEditOpen, onClose: onEditClose } = useDisclosure();

  const toast = useToastContext();

  const fetchData = async (page: number) => {
    try {
      setLoading(true);

      const response = await api.get(`/admin-listings`, { params: { listingTabType: filter, page: page } });

      setData(
        response.data.data.map(({ id, title, images, score, isShared }: any) => ({
          id,
          name: title,
          image: images[0],
          impactX: score,
          status: isShared ? <Text color="green">Shared</Text> : <Text color="#FF0000">Not Yet Shared</Text>,
          actions: isShared ? (
            ''
          ) : (
            <Button size="sm" as={NavLink} to={`/listings/${id}`}>
              Share
            </Button>
          ),
        }))
      );
      setTotalPages(response?.data?.pagination?.totalPages);

      setLoading(false);
    } catch (error) {
      toast && toast('An error occurred', Utils.formatErrorMessage(error), 'error');
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(currentPage);
  }, [filter, currentPage]);

  let columns: Column[] = [
    {
      header: 'Latest listings',
      accessor: 'name',
      align: 'left',
    },
  ];

  if (filter === ListingType.Available) {
    columns.push({
      header: 'Impact',
      accessor: 'impactX',
      align: 'center',
    });
  } else {
    columns.push({
      header: 'Status',
      accessor: 'status',
      align: 'left',
    });

    columns.push({
      header: '',
      accessor: 'actions',
      align: 'center',
    });
  }

  const handleDone = () => {
    currentPage === 1 ? fetchData(1) : setCurrentPage(1);
    onEditClose();
  };

  const [toEdit, setToEdit] = useState(null);

  const editListing = async (data: any) => {
    setSaving(true);
    try {
      const { id, description, title, notes, expirationDate } = data;
      const payload = { description, title, notes, expirationDate };

      await api.patch(`/admin-listings/${id}`, payload);
      handleDone();
    } catch (error) {
      toast && toast('An error occurred', Utils.formatErrorMessage(error), 'error');
    }

    setSaving(false);
  };

  const handleEditClick = async ({ id }: any) => {
    const response = await api.get(`/admin-listings/${id}`);

    setToEdit(response.data.data);
    onEditOpen();
  };

  const handleTabChange = (index: number, content: ListingType) => {
    setFilter(content);
  };

  return (
    <>
      <ModalDialog title={`Edit listing`} isOpen={isEditOpen} onClose={handleDone}>
        <ListingForm onSubmit={editListing} listing={toEdit} savingListing={saving} />
      </ModalDialog>
      <DataTabs data={tabData} onTabChange={handleTabChange} syncWithUrl>
        <AdminDataTable
          data={data}
          loading={loading}
          columns={columns}
          onEditClick={handleEditClick}
          isshowPagination={true}
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={setCurrentPage}
          enableEdit={filter === ListingType.Available}
        ></AdminDataTable>
      </DataTabs>
    </>
  );
};

export default AdminDepartments;
