import { NewsItem } from '../components/NewsItem';
import React, { useEffect, useState } from 'react';
import { Stack, SimpleGrid, Card, CardHeader, CardBody, Box } from '@chakra-ui/react';

import PageGrid from '../components/PageGrid';
import ScoreCard from '../components/ScoreCard';
import TextHeading from '../components/TextHeading';
import Border from '../components/XBorder';
import ExploreCTACard from '../components/ExploreCTACard';

import api from '../api';
import Pagination from '../components/Pagination';

interface Props {
  platform: boolean;
}

const NewsPage: React.FC<Props> = ({ platform }) => {
  const [xsilioNews, setXsilioNews]: any = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const limit = 5;

  const fetchData = async (page: number) => {
    try {
      const apiUrl = platform ? '/news/platform' : '/news/organisation';
      const response = await api.get(apiUrl, {
        params: {
          page: page,
          limit: limit,
        },
      });
      setXsilioNews(response?.data?.data);
      setTotalPages(response?.data?.pagination?.totalPages);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData(currentPage);
  }, [currentPage]);

  return (
    <PageGrid
      sideBar={
        <Stack gap="6">
          <ScoreCard />
          <ExploreCTACard />
        </Stack>
      }
    >
      <SimpleGrid gap={6} columns={1}>
        <Card>
          <CardHeader>
            <TextHeading fontSize={'21px'} title={'XSILIO News & Updates'} borderColor="#315D4F"></TextHeading>
          </CardHeader>
          <CardBody>
            {xsilioNews &&
              xsilioNews?.map((article: any) => (
                <>
                  <div key={article.id}>
                    <NewsItem article={article} />
                    <Border mt={5} mb={6} />
                  </div>
                </>
              ))}
          </CardBody>
        </Card>
        <Box flex={1} display="flex" justifyContent="flex-end" alignItems="center" mt={5} mb={5}>
          <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={setCurrentPage} />
        </Box>
      </SimpleGrid>
    </PageGrid>
  );
};

export default NewsPage;
