import React, { useEffect, useState } from 'react';
import { Stack } from '@chakra-ui/react';

import api from '../api';
import { ListingType } from '../constants';
import { useAuth } from '../AuthContext';

import PageGrid from '../components/PageGrid';
import DataTable from '../components/ListingsTable';
import DataTabs from '../components/XTabs';
import ScoreCard from '../components/ScoreCard';
import ChallengeCard from '../components/ChallengeCard';
import IncreaseCTACard from '../components/IncreaseCTACard';

const ListingsPage: React.FC = () => {
  const { isAffiliate } = useAuth();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [filter, setFilter] = useState<ListingType | null>(null);
  const [catId, setcatId] = useState('');
  const [subCatId, setSubCatId] = useState('');
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [subCategoryOptions, setSubCategoryOptions] = useState([]);

  const config = {
    page: 1,
    limit: 1000,
  };

  const affiliate = isAffiliate();

  let tabData = [
    {
      label: 'Available',
      content: ListingType.Available,
    },
  ];

  if (affiliate) {
    tabData.push({
      label: 'Closed',
      content: ListingType.Closed,
    });
  } else {
    tabData = [
      {
        label: 'Available',
        content: ListingType.Available,
      },
      {
        label: 'Wishlist',
        content: ListingType.Wishlist,
      },
      {
        label: 'Disposing',
        content: ListingType.Disposing,
      },
      {
        label: 'Closed',
        content: ListingType.Closed,
      },
      {
        label: 'Drafts',
        content: ListingType.Drafts,
      },
    ];
  }
  const fetchData = async (page: number = 1) => {
    try {
      if (!filter) {
        return;
      }

      setLoading(true);
      let response;
      if (affiliate) {
        const endpointURL = filter === ListingType.Closed ? `/listings/shared-affiliates/closed` : `/listings/shared-affiliates`;
        response = await api.get(endpointURL, {
          params: { page },
        });
      } else {
        response = await api.get(`/listings`, {
          params: { page, categoryId: catId, subCategoryId: subCatId, ...(filter ? { listingTabType: filter } : {}) },
        });
      }

      setData(response?.data?.data);
      setTotalPages(response?.data?.pagination?.totalPages);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const fetchCategories = async () => {
    try {
      const response = await api.get('/categories', { params: { ...config, sort_by: 'name' } });
      setCategoryOptions(response.data.data.map(({ id, name }: any) => ({ label: name, value: id })));
    } catch (error) {
      console.error(error);
    }
  };

  const fetchSubCategories = async (categoryId: string) => {
    try {
      const response = await api.get('/subcategories', { params: { ...config, sort_by: 'name', category: categoryId } });
      setSubCategoryOptions(response.data.data.map(({ id, name }: any) => ({ label: name, value: id })));
    } catch (error) {
      console.error(error);
    }
  };

  const handleCategoryChange = (categoryId: string) => {
    setcatId(categoryId);
    setSubCatId('');
    fetchSubCategories(categoryId);
    setCurrentPage(1);
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  useEffect(() => {
    fetchData(currentPage);
  }, [currentPage, filter]);

  const handleTabChange = (index: number, content: ListingType) => {
    setcatId('');
    setSubCatId('');
    setSubCategoryOptions([]);
    setFilter(content);
    setCurrentPage(1);
  };

  const handleApply = async () => {
    try {
      if (!filter) {
        return;
      }

      setLoading(true);
      let response;
      if (affiliate) {
        const endpointURL = filter === ListingType.Closed ? `/listings/shared-affiliates/closed` : `/listings/shared-affiliates`;
        response = await api.get(endpointURL, {
          params: { page: 1 },
        });
      } else {
        response = await api.get(`/listings`, {
          params: { page: 1, categoryId: catId, subCategoryId: subCatId, ...(filter ? { listingTabType: filter } : {}) },
        });
      }

      setData(response?.data?.data);
      setTotalPages(response?.data?.pagination?.totalPages);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const handleClear = async () => {
    try {
      if (!filter) {
        return;
      }

      setLoading(true);
      let response;
      if (affiliate) {
        const endpointURL = filter === ListingType.Closed ? `/listings/shared-affiliates/closed` : `/listings/shared-affiliates`;
        response = await api.get(endpointURL, {
          params: { page: 1 },
        });
      } else {
        response = await api.get(`/listings`, {
          params: { page: 1, categoryId: '', subCategoryId: '', ...(filter ? { listingTabType: filter } : {}) },
        });
      }

      setData(response?.data?.data);
      setcatId('');
      setSubCatId('');
      setSubCategoryOptions([]);

      setTotalPages(response?.data?.pagination?.totalPages);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };
  return (
    <PageGrid
      sideBar={
        <Stack gap="6">
          <ScoreCard />
          {!isAffiliate() && <ChallengeCard />}
          {isAffiliate() && filter !== ListingType.Closed && <IncreaseCTACard listings={data} />}
        </Stack>
      }
    >
      <DataTabs data={tabData} onTabChange={handleTabChange} syncWithUrl={true}>
        {filter && (
          <DataTable
            data={data}
            loading={loading}
            onItemChanged={fetchData}
            listingType={filter}
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={setCurrentPage}
            wishlist={filter === ListingType.Wishlist}
            isshowPagination={true}
            catId={catId}
            subCatId={subCatId}
            categoryOptions={categoryOptions}
            subCategoryOptions={subCategoryOptions}
            onCategoryChange={handleCategoryChange}
            onSubCategoryChange={setSubCatId}
            handleApply={handleApply}
            handleClear={handleClear}
            isfilterdisplay={true}
          />
        )}
      </DataTabs>
    </PageGrid>
  );
};

export default ListingsPage;
