import React, { useState, useEffect } from 'react';
import {
  Text,
  Card,
  Box,
  Grid,
  GridItem,
  Stack,
  Image,
  Tag,
  TagLabel,
  SimpleGrid,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Center,
  WrapItem,
  Flex,
  Skeleton,
  SkeletonText,
  IconButton,
  Spinner,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { isPast, isToday } from 'date-fns';

import TextHeading from '../components/TextHeading';
import Border from '../components/XBorder';
import ConfirmDialog from '../components/ConfirmDialog';
import { ImageUpload } from '../components/ImageUpload';
import { useToastContext } from '../ToastContext';

import api from '../api';
import { useAuth } from '../AuthContext';
import { ReactComponent as AcquiredIlustration } from '../assets/asset-asquired.svg';
import { Utils } from '../services';

import { MessageDialog } from '../components/MessageDialog';
import ModalDialog from '../components/XModal';
import ListingForm from '../components/ListingForm';

import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

const ListingInforRow = ({ title, value, loading }: { title: string; value: string; loading: boolean }) => {
  const paddingY = '2px';
  return (
    <Flex direction={'row'} justifyContent={'flex-start'}>
      <Box pl="0" width="220px" py={paddingY} fontSize={'14px'}>
        {title}:
      </Box>
      <Box
        pl="0"
        py={paddingY}
        overflow={'hidden'}
        whiteSpace={'nowrap'}
        textOverflow={'ellipsis'}
        textAlign={'left'}
        width="100%"
        fontSize={'14px'}
      >
        <Skeleton isLoaded={!loading} overflow={'hidden'} whiteSpace={'nowrap'} textOverflow={'ellipsis'}>
          <strong> {value}</strong>
        </Skeleton>
      </Box>
    </Flex>
  );
};

interface Props {
  data: any;
  onDataChange: () => void;
  loading: boolean;
}

export const ListingDetails: React.FC<Props> = ({ data, onDataChange, loading }) => {
  const { user, updateUserData, isAffiliate } = useAuth();
  const [listingData, setListingData] = useState(data);
  const { isOpen: isMessageDialogOpen, onToggle: toggleMessageDalogOpen, onClose: onMessageDalogClose } = useDisclosure();
  const toast = useToastContext();

  const [uploading, setUploading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setListingData(data);
    if (data?.images && data.images.length > 0) {
      setSelectedImage(data.images[0]);
    }
  }, [data]);

  // IMAGES
  const [selectedImage, setSelectedImage] = useState('');

  const handleImageUploadChange = async (image: File) => {
    let formData = new FormData();

    formData.append('files', image);

    try {
      setUploading(true);
      await api.patch(`/listings/${listingData.id}/images`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      // TODO
      const {
        data: { data },
      } = await api.get(`/listings/${listingData.id}`);
      setListingData(data);

      if (data.images && data.images.length > 0) {
        setSelectedImage(data.images[0]);
      }
    } catch (error) {
      toast('An error occurred', Utils.formatErrorMessage(error), 'error');
    }

    setUploading(false);
  };

  // ACQUISITION
  const [acquireing, setAcquireing] = useState(false);
  const {
    isOpen: isConfirmAcquisitionDialogOpen,
    onOpen: onConfirmAcquisitionDialogOpen,
    onClose: onConfirmAcquisitionDialogClose,
  } = useDisclosure();
  const { isOpen: isAquiredDialogOpen, onOpen: onAcquiredDialogOpen, onClose: onAcquiredDialogClose } = useDisclosure();

  const handleAcquiredDialogCLose = () => {
    onAcquiredDialogClose();
    updateUserData();
    navigate('/marketplace?tab=closed');
  };

  const isMyListing = () => {
    return user?.id === listingData?.createdBy?.id;
  };

  const { isOpen: isListingFormDialogOpen, onOpen: onListingFormDialogOpen, onClose: onListingFormDialogClose } = useDisclosure();

  const handleListingFormDialogClose = () => {
    onListingFormDialogClose();
  };

  const handleFormSubmitted = async () => {
    onDataChange();
    onListingFormDialogClose();
  };

  const handleWishlistClick = async () => {
    try {
      const newListingData = { ...listingData, isInWishlist: !listingData.isInWishlist };
      setListingData(newListingData);

      if (!newListingData.isInWishlist) {
        await api.delete(`/listings/wishlist/${listingData.id}`);
        toast(`Removed from wishlist`, `${listingData.title} removed from wishlist`);
      } else {
        await api.post(`/listings/wishlist/${listingData.id}`);
        toast(`Added to wishlist`, `${listingData.title} added to wishlist`);
      }
    } catch (error) {
      toast('An error occurred', Utils.formatErrorMessage(error), 'error');
    }
  };

  const schema = yup.object().shape({
    quantity: yup
      .number()
      .typeError('Quantity must be a number')
      .min(1, 'Quantity must be at least 1')
      .max(listingData?.remaining_quantity, `Quantity cannot exceed ${listingData?.remaining_quantity}`)
      .required('Quantity is required'),
  });
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: { quantity: 1 },
  });

  const handleAcquireClick = async (data: { quantity: number }) => {
    setAcquireing(true);

    const apiURL = isAffiliate()
      ? `/listings/affiliates/acquisition/${listingData.id}?acquire_quantity=${data.quantity}`
      : `/listings/acquisition/${listingData.id}?acquire_quantity=${data.quantity}`;
    try {
      await api.post(apiURL);
      reset();
      onAcquiredDialogOpen();
    } catch (error) {
      toast('An error occurred', Utils.formatErrorMessage(error), 'error');
    }

    setAcquireing(false);
  };

  const handleaquirebtnclick = () => {
    listingData?.remaining_quantity <= 1 ? handleAcquireClick({ quantity: 1 }) : onConfirmAcquisitionDialogOpen();
  };

  return (
    <>
      {listingData && (
        <ModalDialog title={`Edit your listing`} isOpen={isListingFormDialogOpen} onClose={handleListingFormDialogClose}>
          <ListingForm onSubmitted={handleFormSubmitted} listing={listingData} />
        </ModalDialog>
      )}
      {listingData && (
        <MessageDialog
          subject={listingData?.title}
          listingId={listingData.id}
          recipient={listingData?.createdBy}
          isOpen={isMessageDialogOpen}
          onClose={onMessageDalogClose}
        />
      )}
      <ConfirmDialog
        title="Acquire listing?"
        message="Are you sure you want to acquire this listing?"
        isOpen={isConfirmAcquisitionDialogOpen}
        onClose={onConfirmAcquisitionDialogClose}
        onConfirm={() => handleSubmit(handleAcquireClick)()}
        handleSubmit={handleSubmit}
        control={control}
        errors={errors}
        isConfirming={acquireing}
        confirmText="Acquire"
        isInput={true}
        maxQuantity={listingData?.remaining_quantity}
        reset={reset}
      />
      <Modal isOpen={isAquiredDialogOpen} onClose={handleAcquiredDialogCLose} size={'xl'}>
        <ModalOverlay />
        <ModalContent paddingY={5}>
          <ModalBody>
            <ModalCloseButton />
            <Flex direction={'row'}>
              <WrapItem>
                <Center bg="#DEE5E2" borderRadius={'full'} width={'183px'} height={'183px'}>
                  <AcquiredIlustration />
                </Center>
              </WrapItem>
              <WrapItem paddingY={3} paddingLeft={5} paddingRight={3}>
                <Stack>
                  <Text color={'#000'} fontSize={'21px'} fontWeight={'700'} paddingBottom={4}>
                    Listing Acquired
                  </Text>
                  <Text fontSize={'18px'} color={'rgba(0, 0, 0, 0.5)'}>
                    Well done. You successfully acquired this listing. You can find your acquired listings in the "Closed" tab on the
                    Marketplace page.
                  </Text>
                </Stack>
              </WrapItem>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
      <Card padding={'21px'}>
        <Grid templateColumns="repeat(5, 1fr)" gap={6}>
          <GridItem>
            <Stack direction={'column'} gap={7}>
              {loading && (
                <>
                  <Skeleton height="115px" width="100%" borderRadius={'10px'} />
                  <Skeleton height="115px" width="100%" borderRadius={'10px'} />
                  <Skeleton height="115px" width="100%" borderRadius={'10px'} />
                </>
              )}
              {!loading && (
                <>
                  {listingData?.images?.length > 0 &&
                    listingData?.images
                      .slice(0, 3)
                      .map((imageUrl: string, index: number) => (
                        <Image
                          src={imageUrl}
                          height={'115px'}
                          alt={listingData?.title}
                          borderRadius={'10px'}
                          key={index}
                          objectFit={selectedImage ? 'contain' : 'cover'}
                          onClick={() => setSelectedImage(imageUrl)}
                          fallbackSrc={'/placeholder.jpg'}
                        />
                      ))}

                  {(!listingData?.images || listingData?.images?.length === 0) && (
                    <Image height={'115px'} alt={listingData?.title} borderRadius={'10px'} src={'/placeholder.jpg'} objectFit={'cover'} />
                  )}

                  {isMyListing() && listingData?.images?.length < 3 && (
                    <Center height={'115px'}>
                      {uploading && <Spinner size="xl" />}
                      {!uploading && (
                        <ImageUpload onImageChange={handleImageUploadChange} maxNumber={3 - (listingData?.images?.length || 0)} />
                      )}
                    </Center>
                  )}
                </>
              )}
            </Stack>
          </GridItem>
          <GridItem colSpan={4}>
            <Box borderRadius={'10px'} overflow={'hidden'}>
              {loading && <Skeleton width="100%" height={'400px'} />}
              {!loading && (
                <Image
                  width={'100%'}
                  src={selectedImage}
                  fallbackSrc={'/placeholder.jpg'}
                  alt={listingData?.title}
                  objectFit={selectedImage ? 'contain' : 'cover'}
                  objectPosition={'center'}
                  height={'400px'}
                />
              )}
            </Box>
          </GridItem>
        </Grid>
        <Box pb={1} pt={2}>
          <TextHeading borderColor="#315D4F" color={'#315D4F'} fontSize="21px" title={listingData?.title} loading={loading}>
            <Box pt={0}>
              {listingData?.product?.subCategory && (
                <>
                  {' '}
                  <Tag marginRight={3} mb={3}>
                    <TagLabel>{listingData?.product?.subCategory?.category?.name}</TagLabel>
                  </Tag>
                  <Tag marginRight={3}>
                    <TagLabel>{listingData?.product?.subCategory?.name}</TagLabel>
                  </Tag>
                </>
              )}
              {!isMyListing() && !isAffiliate() && (
                <IconButton
                  variant={'tertiary'}
                  bg={listingData?.isInWishlist ? 'brand.900' : '#E2E8E7'}
                  icon={
                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="13" viewBox="0 0 15 13" fill="none">
                      <path
                        d="M7.50409 12L1.98727 6.93329C-1.011 3.89326 3.39646 -1.94358 7.50409 2.77859C11.6117 -1.94358 15.9992 3.91353 13.0209 6.93329L7.50409 12Z"
                        stroke={listingData?.isInWishlist ? '#fff' : '#315D4F'}
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  }
                  aria-label={listingData?.isInWishlist ? 'Remove from Wishlist' : 'Add to Wishlist'}
                  isRound
                  size={'sm'}
                  mb={3}
                  onClick={handleWishlistClick}
                ></IconButton>
              )}
            </Box>
          </TextHeading>
        </Box>
        <SimpleGrid columns={2}>
          <Box paddingY={5}>
            {loading && <SkeletonText />}
            {!loading && <Text>{listingData?.description}</Text>}
          </Box>
          <Box paddingY={5}>
            {loading && <SkeletonText />}
            {!loading && <Text>{listingData?.notes}</Text>}
          </Box>
        </SimpleGrid>
        <Border marginTop={2} marginBottom={7} />
        <SimpleGrid columns={2} pb={10}>
          <Box>
            <ListingInforRow title="Product" value={listingData?.product?.name} loading={loading} />
            <ListingInforRow title="Materials" value={listingData?.materials} loading={loading} />
            <ListingInforRow title="Condition" value={listingData?.condition?.condition} loading={loading} />
            <ListingInforRow title="Age" value={`${listingData?.age} ${listingData?.age > 1 ? 'years' : 'year'}`} loading={loading} />
            {listingData?.location && (
              <ListingInforRow
                title="Location"
                value={`${listingData?.location.street}, ${listingData?.location.postCode}, ${listingData?.location.city}, ${listingData?.location.country}`}
                loading={loading}
              />
            )}
            <ListingInforRow
              title="Quantity"
              value={user?.id === listingData?.createdBy?.id ? listingData?.quantity : listingData?.remaining_quantity}
              loading={loading}
            />
            <ListingInforRow
              title="impactX score"
              value={user?.id === listingData?.createdBy?.id ? listingData?.score : listingData?.remaining_score}
              loading={loading}
            />
            {/* <ListingInforRow title="Available Quantity" value={listingData?.remaining_quantity} loading={loading} /> */}
            {/* <ListingInforRow title="Available impactX score" value={listingData?.remaining_score} loading={loading} /> */}
          </Box>

          <Stack px="3">
            {isMyListing() && listingData?.status === 'draft' && (
              <Skeleton isLoaded={!loading} width={'100%'} height={16}>
                <Button onClick={onListingFormDialogOpen} width={'100%'}>
                  Edit
                </Button>
              </Skeleton>
            )}

            {(isAffiliate() ||
              (!isMyListing() && (!isPast(new Date(listingData?.expirationDate)) || isToday(new Date(listingData?.expirationDate))))) &&
              listingData?.status !== 'closed' && (
                <>
                  <Skeleton isLoaded={!loading} width={'100%'} height={16}>
                    <Button onClick={handleaquirebtnclick} width={'100%'} isLoading={acquireing}>
                      Acquire now
                    </Button>
                  </Skeleton>
                  <Skeleton isLoaded={!loading} width={'100%'} height={10}>
                    <Button variant={'outline'} width={'100%'} onClick={toggleMessageDalogOpen}>
                      Send message
                    </Button>
                  </Skeleton>
                </>
              )}
          </Stack>
        </SimpleGrid>
      </Card>
    </>
  );
};

export default ListingDetails;
