import React, { useState, useEffect } from 'react';
import { Flex, Box, Button, Input, Stack, Text, Select } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import api from '../api';

import { useToastContext } from '../ToastContext';
import { Utils } from '../services';

import { FormField } from './FormField';
import { FieldValidationMessage } from './FieldValidationMessage';

interface Props {
  onSubmit: (data: any) => void;
  product?: any;
  saving: boolean;
}

const schema = yup.object().shape({
  name: yup.string().required(),
  score: yup.number().required('This field is required').typeError('This field is required'),
  subCategoryId: yup.string().required(),
});




export const ProductForm: React.FC<Props> = ({ onSubmit, product, saving }) => {
  const {
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      ...(!product
        ? {}
        : {
            name: product.name || '',
            categoryId: product.category || '',
            subCategoryId: product.subCategory || '',
            score: Number(product.score) || '',
          }),
    },
    resolver: yupResolver(schema),
  });

  const toast = useToastContext();
  const [errorMessage, setErrorMessage] = useState('');

  const config = {
    page:1,
    limit:1000
  }

  const handleFormSubmit = async (data: any) => {
    try {
      onSubmit({
        ...(product ? { id: product.id } : {}),
        ...data,
      });
    } catch (error: any) {
      let toastMessage = `An error occurred: \n`;

      error.response.data.errors.forEach(({ message }: any) => (toastMessage += '* ' + message + '\n'));

      setErrorMessage(toastMessage);
    }
  };

  const [categoryOptions, setCategoryOptions] = useState([]);
  const [subCcategoryOptions, setSubCategoryOptions] = useState([]);

  const fetchSubcategories = async (categoryId: string) => {
    try {
      const { data }: any = await api.get('/subcategories', { params: { ...config,sort_by:'name', category: categoryId } });
      setSubCategoryOptions(data.data.map(({ id, name }: any) => ({ label: name, value: id })));
    } catch (e) {
      toast('An error occurred', Utils.formatErrorMessage(e), 'error');
    }
  };

  const fetchData = async () => {
    try {
      const [categoriesResponse] = await Promise.all([api.get('/categories',{params:{...config,sort_by:'name'}})]);

      setCategoryOptions(categoriesResponse.data.data.map(({ id, name }: any) => ({ label: name, value: id })));

      if (product) {
        setValue('categoryId', product.subCategory.category.id);

        await fetchSubcategories(product.subCategory.category.id);
        setValue('subCategoryId', product.subCategory.id);
      }
    } catch (error) {
      toast && toast('An error occurred', Utils.formatErrorMessage(error), 'error');
    }
  };

  useEffect(() => {
    const subscription = watch(async (value: any, { name, type }: any) => {
      if (type === 'change') {
        if (name === 'categoryId') {
          fetchSubcategories(value[name]);
          setValue('subCategoryId', '');
        }
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Box as="form" onSubmit={handleSubmit(handleFormSubmit)}>
      <Stack gap={4} rowGap={1}>
        <Text color="brand.900" fontSize={'md'} mb={4}>
          Type Asset Name, choose a Category, choose Sub-Category, and hit the submit button here.
        </Text>
      </Stack>

      <Stack gap={4} rowGap={1}>
        <FormField
          fieldName={'name'}
          errors={errors}
          control={control}
          render={({ field }: any) => <Input {...field} autoFocus placeholder={'Asset name*'} />}
        />
        <FormField
          fieldName={'categoryId'}
          errors={errors}
          control={control}
          render={({ field }: any) => (
            <Select {...field} placeholder="Category">
              {categoryOptions.map(({ label, value }: any) => (
                <option key={value} value={value}>
                  {label}
                </option>
              ))}
            </Select>
          )}
        />
        <FormField
          fieldName={'subCategoryId'}
          errors={errors}
          control={control}
          render={({ field }: any) => (
            <Select {...field} placeholder="Sub-Category" disabled={subCcategoryOptions.length === 0}>
              {subCcategoryOptions.map(({ label, value }: any) => (
                <option key={value} value={value}>
                  {label}
                </option>
              ))}
            </Select>
          )}
        />
        <FormField
          fieldName={'score'}
          errors={errors}
          control={control}
          render={({ field }: any) => <Input type="number" {...field} placeholder={'Score*'} />}
        />
      </Stack>

      {errorMessage && (
        <FieldValidationMessage mt={-2} pb={6}>
          {errorMessage}
        </FieldValidationMessage>
      )}

      <Flex direction="row" pt={6} justifyContent={'space-between'}>
        <Box />
        <Button type="submit" isLoading={saving} mr={2}>
          Submit
        </Button>
      </Flex>
    </Box>
  );
};

export default ProductForm;
