import React, { useEffect, useState } from 'react';
import { Card, CardHeader, CardBody, Stack, GridItem, Text, Skeleton } from '@chakra-ui/react';
import { useSearchParams } from 'react-router-dom';

import api from '../api';
import { useAuth } from '../AuthContext';

import { ListingType } from '../constants';

import PageGrid from '../components/PageGrid';
import DataTable from '../components/ListingsTable';
import ScoreCard from '../components/ScoreCard';
import NewsCard from '../components/NewsCard';

const SearchResultsPage: React.FC = () => {
  const { isAffiliate } = useAuth();
  const [data, setData]: any = useState(null);
  const [loading, setLoading] = useState(true);

  const [searchParams, setSearchParams] = useSearchParams();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  

  const fetchData = async (page: number = 1) => {
    try {
      setLoading(true);
      const response = await api.get(`/listings/search`, { params: { title: searchParams.get('query') ?? "", page } });

      setData(response.data.data);
      setTotalPages(response?.data?.pagination?.totalPages);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(currentPage);
  }, [searchParams, currentPage]);

  return (
    <PageGrid
      sideBar={
        <GridItem minWidth={'250px'}>
          <Stack gap="6">
            <ScoreCard />
            {!isAffiliate() && <NewsCard />}
          </Stack>
        </GridItem>
      }
    >
      <Card>
        <CardHeader></CardHeader>
        <CardBody>
          <Skeleton isLoaded={!loading}>
            <Text color="#878790">
              <strong>{data?.length}</strong> results
            </Text>
          </Skeleton>
          <DataTable
            data={data}
            loading={loading}
            onItemChanged={fetchData}
            listingType={ListingType.Available}
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={setCurrentPage}
            isshowPagination={true}
            noActions
            emptyMessage="No results found. Please try another search."
          ></DataTable>
        </CardBody>
      </Card>
    </PageGrid>
  );
};

export default SearchResultsPage;
 