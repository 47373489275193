import React, { useEffect, useState } from 'react';
import { Card, CardBody, useDisclosure } from '@chakra-ui/react';

import api from '../../api';

import { AdminDataTable, Column } from '../../components/AdminDataTable';
import { useToastContext } from '../../ToastContext';
import { Utils } from '../../services';
import { ModalDialog } from '../../components/XModal';
import { AffiliatePartnerForm } from '../../components/AffiliatePartnerForm';
import { AddButton } from '../../components/AddButton';

export const AdminOrganizations: React.FC = () => {
  const [data, setData]: any = useState(null);
  const [loading, setLoading] = useState(true);
  const [deleteing, setDeleteing] = useState(false);
  const [saving, setSaving] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const { isOpen: isAddNewOpen, onOpen: onAddNewOpen, onClose: onAddNewClose } = useDisclosure();
  const { isOpen: isEditOpen, onOpen: onEditOpen, onClose: onEditClose } = useDisclosure();

  const toast = useToastContext();

  const fetchData = async (page: number) => {
    try {
      setLoading(true);
      const response = await api.get(`/affiliate-partner`, {
        params: {
          page: page,
          limit:1000
        },
      });

      setData(
        response.data.data.map(({ id, name, logoUrl, score }: any) => ({
          id,
          name,
          image: logoUrl,
          impactX: score.impactXScore,
        }))
      );
      setTotalPages(response?.data?.pagination?.totalPages);
      setLoading(false);
    } catch (error) {
      toast && toast('An error occurred', Utils.formatErrorMessage(error), 'error');
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(currentPage);
  }, [currentPage]);

  const columns: Column[] = [
    {
      header: 'Affiliate Name',
      accessor: 'name',
      align: 'left',
    },
    {
      header: 'impactX',
      accessor: 'impactX',
      align: 'center',
    },
  ];

  const deleteOrganization = async (id: string) => {
    setDeleteing(true);
    try {
      await api.delete(`/affiliate-partner/${id}`);
      // fetchData();
      currentPage === 1 ? fetchData(1) : setCurrentPage(1);
    } catch (error) {
      toast && toast('An error occurred', Utils.formatErrorMessage(error), 'error');
    }
    setDeleteing(false);
  };

  const addOrganization = async (orgData: any) => {
    setSaving(true);
    try {
      const { data } = await api.post(`/affiliate-partner/`, {
        ...Utils.cleanPayload(orgData),
        isAffiliate: true,
      });

      toast('Successfully created', 'Affiliate partner successfully created', 'success');
      setSaving(false);
      return data;
    } catch (error: any) {
      setSaving(false);
      return error.response;
    }
  };

  const handleDone = () => {
    currentPage === 1 ? fetchData(1) : setCurrentPage(1);
    onAddNewClose();
    onEditClose();
  };

  const [toEdit, setToEdit] = useState(null);

  const editOrganization = async (data: any) => {
    setSaving(true);
    try {
      const { id, ...payload } = data;
      await api.patch(`/affiliate-partner/${id}`, payload);
      setSaving(false);
      return data;
    } catch (error) {
      setSaving(false);
      return error;
    }
  };

  const handleEditClick = async ({ id }: any) => {
    const response = await api.get(`/affiliate-partner/${id}`);

    setToEdit(response.data.data);
    onEditOpen(); 
  };

  return (
    <>
      <ModalDialog title={`Add New Affiliate Partner`} isOpen={isAddNewOpen} onClose={onAddNewClose}>
        <AffiliatePartnerForm onSubmit={addOrganization} saving={saving} onDone={handleDone} />
      </ModalDialog>
      <ModalDialog title={`Edit Affiliate Partner`} isOpen={isEditOpen} onClose={onEditClose}>
        <AffiliatePartnerForm onSubmit={editOrganization} saving={saving} organization={toEdit} onDone={handleDone} />
      </ModalDialog>
      <Card>
        <CardBody>
          <AddButton onClick={onAddNewOpen} />
          <AdminDataTable
            data={data}
            loading={loading}
            columns={columns}
            deleteing={deleteing}
            onDelete={deleteOrganization}
            onEditClick={handleEditClick}
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={setCurrentPage}
            isshowPagination={true}
          ></AdminDataTable>
        </CardBody>
      </Card>
    </>
  );
};

export default AdminOrganizations;
