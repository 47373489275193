import React, { useEffect, useState } from 'react';
import { Card, CardBody, useDisclosure } from '@chakra-ui/react';

import api from '../../api';

import { AdminDataTable, Column } from '../../components/AdminDataTable';
import { useToastContext } from '../../ToastContext';
import { Utils } from '../../services';
import { ModalDialog } from '../../components/XModal';
import { CategoryForm } from '../../components/CategoryForm';
import { AddButton } from '../../components/AddButton';

export const AdminCategories: React.FC = () => {
  const [data, setData]: any = useState(null);
  const [loading, setLoading] = useState(true);
  const [deleteing, setDeleteing] = useState(false);
  const [saving, setSaving] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const { isOpen: isAddNewOpen, onOpen: onAddNewOpen, onClose: onAddNewClose } = useDisclosure();
  const { isOpen: isEditOpen, onOpen: onEditOpen, onClose: onEditClose } = useDisclosure();

  const toast = useToastContext();

  const fetchData = async (page: number) => {
    try {
      setLoading(true);
      const response = await api.get(`/categories`, {
        params: {
          page: page,
        },
      });

      setData(
        response.data.data.map(({ id, name, logo }: any) => ({
          id,
          name,
          image: logo,
        }))
      );
      setTotalPages(response?.data?.pagination?.totalPages);
      setLoading(false);
    } catch (error) {
      toast && toast('An error occurred', Utils.formatErrorMessage(error), 'error');
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(currentPage);
  }, [currentPage]);

  const columns: Column[] = [
    {
      header: 'Category Name',
      accessor: 'name',
      align: 'left',
    },
  ];

  const deleteCategory = async (id: string) => {
    setDeleteing(true);
    try {
      await api.delete(`/categories/${id}`);
      toast('Successfully deleted', 'Category successfully created', 'success');
      // fetchData(currentPage);
      currentPage === 1 ? fetchData(1) : setCurrentPage(1);
    } catch (error) {
      toast && toast('An error occurred', Utils.formatErrorMessage(error), 'error');
    }
    setDeleteing(false);
  };

  const addCategory = async (data: any) => {
    setSaving(true);
    try {
      await api.post(`/categories/`, {
        ...Utils.cleanPayload(data),
      });
      toast('Successfully created', 'Category successfully created', 'success');
      // fetchData();
      currentPage === 1 ? fetchData(1) : setCurrentPage(1);
      onAddNewClose();
    } catch (error) {
      toast && toast('An error occurred', Utils.formatErrorMessage(error), 'error');
    }
    setSaving(false);
  };

  const [toEdit, setToEdit] = useState(null);

  const editCategory = async (data: any) => {
    setSaving(true);
    try {
      const { id, ...payload } = data;
      await api.patch(`/categories/${id}`, payload);
      toast('Successfully updated', 'Category successfully updated', 'success');
      // fetchData();
      currentPage === 1 ? fetchData(1) : setCurrentPage(1);
    } catch (error) {
      toast && toast('An error occurred', Utils.formatErrorMessage(error), 'error');
    }
    onEditClose();
    setSaving(false);
  };

  const handleEditClick = async ({ id }: any) => {
    const response = await api.get(`/categories/${id}`);

    setToEdit(response.data.data);
    onEditOpen();
  };

  return (
    <>
      <ModalDialog size="md" title={`Add New Category`} isOpen={isAddNewOpen} onClose={onAddNewClose}>
        <CategoryForm onSubmit={addCategory} saving={saving} />
      </ModalDialog>
      <ModalDialog size="md" title={`Edit Category`} isOpen={isEditOpen} onClose={onEditClose}>
        <CategoryForm onSubmit={editCategory} saving={saving} category={toEdit} />
      </ModalDialog>
      <Card>
        <CardBody>
          <AddButton onClick={onAddNewOpen} />
          <AdminDataTable
            data={data}
            loading={loading}
            columns={columns}
            deleteing={deleteing}
            onDelete={deleteCategory}
            onEditClick={handleEditClick}
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={setCurrentPage}
            isshowPagination={true}
            enableEdit
            noAvatar
          ></AdminDataTable>
        </CardBody>
      </Card>
    </>
  );
};

export default AdminCategories;
