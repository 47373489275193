import React, { useState, useEffect } from 'react';
import { Flex, Box, Button, Input, Select, Stack, Text, SimpleGrid, GridItem, Center, Tabs, TabPanels, TabPanel } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { Utils } from '../services';
import { UserType } from '../constants';
import { useToastContext } from '../ToastContext';

import { FormField } from './FormField';
import { FieldValidationMessage } from './FieldValidationMessage';
import { ImageUpload } from './ImageUpload';
import { useAuth } from '../AuthContext';
import api from '../api';

interface Props {
  onSubmit: (data: any) => any;
  user?: any;
  saving: boolean;
  onDone: () => void;
}

export const UserForm: React.FC<Props> = ({ onSubmit, user, saving, onDone }) => {
  const { user: authUser } = useAuth();
  
  const [userData, setUserData] = useState(user || null);

  const defaulSchema = {
    fullName: yup.string().required(),
    email: yup.string().required(),
    ...(authUser?.userType === UserType.ClentAdmin &&
      userData?.userType !== UserType.ClentAdmin && {
        departmentId: yup.string().required(),
      }),
    ...(authUser?.userType === UserType.XsilioAdmin && {
      userType: yup.string().required(),
    }),
  };

  const [schema, setSchema] = useState(yup.object().shape(defaulSchema));

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    trigger,
    formState: { errors, isDirty },
    getValues,
  } = useForm({
    defaultValues: {
      ...(!user
        ? {}
        : {
            fullName: user.fullName || '',
            email: user.email || '',
            phone: user.phone || '',
            organisationId: user.organisation?.id || '',
            affiliatePartnerId: user.affiliatePartnerId?.id || '',
            departmentId: user.departmentId?.id || '',
            userType: user.userType || '',
            address: {
              city: user.address?.city || '',
              street: user.address?.street || '',
              postCode: user.address?.postCode || '',
              state: user.address?.state || '',
              country: user.address?.country || '',
            },
          }),
    },
    resolver: yupResolver(schema),
  });

  const handleSaveAndFinishClick = async () => {
    if (await trigger()) {
      await handleFormSubmit(getValues());
      onDone();
    }
  };

  const toast = useToastContext();

  const [errorMessage, setErrorMessage] = useState('');
  const [organizationOptions, setOrganizationOptions] = useState([]);
  const [affiliateOptions, setAffiliateOptions] = useState([]);
  const [departmentOptions, setDepartmentOptions] = useState([]);

  let userTypes: string[] = [];

  if (authUser?.userType === 'xsilio admin') {
    userTypes = ['client admin', 'affiliate partner admin'];
  }

  const userTypeOptions = userTypes.map((type: string) => ({
    label: type,
    value: type,
  }));


  const config = {
    page:1,
    limit:1000
  }

  const fetchData = async () => {
    try {
      if (userTypeOptions.length === 1) {
        setValue('userType', userTypeOptions[0].value);
      }

      if (authUser?.userType === UserType.XsilioAdmin) {
        const [organizationsResponse, affiliatePartnersResponse] = await Promise.all([
          api.get('/organisations',{params:config}),
          api.get('/affiliate-partner',{params:config}),
        ]);

        setOrganizationOptions(organizationsResponse.data.data.map(({ id, name }: any) => ({ label: name, value: id })));
        setAffiliateOptions(affiliatePartnersResponse.data.data.map(({ id, name }: any) => ({ label: name, value: id })));
      } else if (authUser?.userType === 'client admin') {
        setUserData({
          ...user,
          organisationId: authUser.organisation.id,
        });
        const departmentsResponse = await api.get(`/departments/organization/${authUser.organisation.id}`,{params:config});
        setDepartmentOptions(departmentsResponse.data.data.map(({ id, name }: any) => ({ label: name, value: id })));

        if (userData && userData.department) {
          setValue('departmentId', userData.department.id);
        }
      } else if (authUser?.userType === 'affiliate partner admin') {
        setUserData({
          ...user,
          affiliatePartnerId: authUser.affiliatePartner.id,
        });
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const [numberOfLicences, setNumberOfLicences] = useState(null);
  const [selectedUserType, setSelectedUserType] = useState();

  useEffect(() => {
    const subscription = watch(async (value, { name, type }: any) => {
      if (type === 'change') {
        if (name === 'organisationId') {
          try {
            const [
              organizationResponse,
              // departmentsResponse
            ] = await Promise.all([
              api.get('/organisations/' + value.organisationId),
              // api.get(`/departments/organization/${value.organisationId}`),
            ]);

            setNumberOfLicences(organizationResponse.data.data.numberOflicences);
            // setDepartmentOptions(departmentsResponse.data.data.map(({ id, name }: any) => ({ label: name, value: id })));
          } catch (e) {
            toast('An error occurred', Utils.formatErrorMessage(e), 'error');
          }
        } else if (name === 'affiliatePartnerId') {
          // const {
          //   data: { data },
          // }: // @ts-ignore
          // any = await api.get('/affiliate-partner/' + value.affiliatePartnerId);
          // setNumberOfLicences(data.numberOflicences);
        } else if (name === 'userType') {
          setSelectedUserType(value.userType);

          if (value.userType === UserType.AffiliatePartnerAdmin) {
            setSchema(
              yup.object().shape({
                ...defaulSchema,
                affiliatePartnerId: yup.string().required(),
              })
            );
          } else if (value.userType === UserType.ClentAdmin) {
            setSchema(
              yup.object().shape({
                ...defaulSchema,
                organisationId: yup.string().required(),
              })
            );
          }
        }
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const [tabIndex, setTabIndex] = useState(0);

  const handleFormSubmit = async (formData: any) => {
    if (tabIndex === 1) {
      onDone();
      return;
    }

    if (!isDirty) {
      setErrorMessage('');
      setTabIndex(1);
      return;
    }

    try {
      let payload = {
        ...Utils.cleanPayload(formData),
      };

      

      if (userData) {
        payload.id = userData.id;
      }

      if (authUser?.userType === UserType.AffiliatePartnerAdmin) {
        payload.affiliatePartnerId = authUser.affiliatePartner.id;
        payload.userType = userData.userType ? userData.userType : UserType.AffiliatePartnerUser;
      } else if (authUser?.userType === UserType.ClentAdmin) {
        payload.organisationId = authUser.organisation.id;
        payload.userType = userData.userType ? userData.userType : UserType.ClientUser;
      } 
      // else if (authUser?.userType === UserType.XsilioAdmin) {
      //   const departmentsResponse = await api.get(`/departments/organization/${payload.organisationId}`);
      //   const adminDepartment = departmentsResponse.data.data.find((dep: any) => dep.name === 'Admin');

      //   if (adminDepartment) {
      //     payload.departmentId = adminDepartment.id;
      //   }
      // }

      const resp = await onSubmit(payload);

      if (resp.errors && resp.errors.length > 0) {
        let toastMessage = `An error occurred: \n`;

        resp.errors.forEach(({ message }: any) => (toastMessage += '* ' + message + '\n'));

        setErrorMessage(toastMessage);
      } else if (resp.data.errors && resp.data.errors.length > 0) {
        let toastMessage = `An error occurred: \n`;

        resp.data.errors.forEach(({ message }: any) => (toastMessage += '* ' + message + '\n'));

        setErrorMessage(toastMessage);
      } else {
        setErrorMessage('');
        setUserData(resp.data);
        setTabIndex(1);
      }
    } catch (error: any) {
      console.error(error);
      toast('An error occurred', Utils.formatErrorMessage(error), 'error');
    }
  };

  const [uploading, setUploading] = useState(false);

  const handleImageUploadChange = async (image: File) => {
    setUploading(true);
    let formData = new FormData();
    formData.append('file', image);

    try {
      await api.patch(`/users/picture/${userData.id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    } catch (error) {
      setErrorMessage('An error occurred');
    }

    setUploading(false);
  };

  return (
    <Box as="form" onSubmit={handleSubmit(handleFormSubmit)}>
      <Tabs index={tabIndex}>
        <TabPanels>
          <TabPanel p="0">
            <Text color="brand.900" fontSize={'md'} mb={6}>
              {user ? `Here you can easil edit a user.` : `Here you can easily add a new user.`}
            </Text>

            <SimpleGrid columns={4} gap={4} rowGap={1} mt={1}>
              <GridItem colSpan={2}>
                <Stack gap={4} rowGap={1}>
                  <FormField
                    fieldName={'fullName'}
                    errors={errors}
                    control={control}
                    render={({ field }: any) => <Input {...field} autoFocus placeholder={'Name*'} />}
                  />
                  <FormField
                    fieldName={'email'}
                    errors={errors}
                    control={control}
                    render={({ field }: any) => <Input {...field} placeholder={'Email*'} />}
                  />
                  <FormField
                    fieldName={'phone'}
                    errors={errors}
                    control={control}
                    render={({ field }: any) => <Input {...field} placeholder={'Phone'} />}
                  />
                  <FormField
                    fieldName={'address.postCode'}
                    errors={errors}
                    control={control}
                    render={({ field }: any) => <Input {...field} placeholder={'Postal Code'} />}
                  />
                  <FormField
                    fieldName={'address.country'}
                    errors={errors}
                    control={control}
                    render={({ field }: any) => <Input {...field} placeholder={'Country'} />}
                  />
                </Stack>
              </GridItem>
              <GridItem colSpan={2}>
                <Stack gap={4} rowGap={1}>
                  {authUser?.userType === 'xsilio admin' && (
                    <FormField
                      fieldName={'userType'}
                      errors={errors}
                      control={control}
                      render={({ field }: any) => (
                        <Select {...field} placeholder="Type of user*">
                          {userTypeOptions.map(({ label, value }: any) => (
                            <option key={value} value={value}>
                              {label}
                            </option>
                          ))}
                        </Select>
                      )}
                    />
                  )}
                  {selectedUserType === 'affiliate partner admin' && authUser?.userType === 'xsilio admin' && (
                    <FormField
                      fieldName={'affiliatePartnerId'}
                      errors={errors}
                      control={control}
                      render={({ field }: any) => (
                        <Select {...field} placeholder="Affiliate Partner*">
                          {affiliateOptions.map(({ label, value }: any) => (
                            <option key={value} value={value}>
                              {label}
                            </option>
                          ))}
                        </Select>
                      )}
                    />
                  )}
                  {selectedUserType === 'client admin' && authUser?.userType === 'xsilio admin' && (
                    <FormField
                      fieldName={'organisationId'}
                      errors={errors}
                      control={control}
                      render={({ field }: any) => (
                        <Select {...field} placeholder="Organisation*">
                          {organizationOptions.map(({ label, value }: any) => (
                            <option key={value} value={value}>
                              {label}
                            </option>
                          ))}
                        </Select>
                      )}
                    />
                  )}
                  {authUser?.userType === 'client admin' && (
                    <FormField
                      fieldName={'departmentId'}
                      errors={errors}
                      control={control}
                      render={({ field }: any) => (
                        <Select {...field} placeholder="Department">
                          {departmentOptions.map(({ label, value }: any) => (
                            <option key={value} value={value}>
                              {label}
                            </option>
                          ))}
                        </Select>
                      )}
                    />
                  )}
                  <FormField
                    fieldName={'address.street'}
                    errors={errors}
                    control={control}
                    render={({ field }: any) => <Input {...field} placeholder="Street" />}
                  />{' '}
                  <FormField
                    fieldName={'address.city'}
                    errors={errors}
                    control={control}
                    render={({ field }: any) => <Input {...field} placeholder="City" />}
                  />
                </Stack>
              </GridItem>
            </SimpleGrid>
          </TabPanel>
          <TabPanel p="0">
            <Text color="brand.900" fontSize={'md'} mb={6}>
              Here you can easily upload an image or skip by clicking save.
            </Text>
            <Center>
              <ImageUpload onImageChange={handleImageUploadChange} imageUrl={user?.profilePhoto} />
            </Center>
          </TabPanel>
        </TabPanels>
      </Tabs>

      {errorMessage && (
        <FieldValidationMessage mt={-2} pb={6}>
          {errorMessage}
        </FieldValidationMessage>
      )}

      <Flex direction="row" pt={6} justifyContent={'space-between'}>
        <Center>
          {numberOfLicences && (
            <Text color="brand.900" fontSize={'md'}>
              Number of Licenses: <strong>{numberOfLicences}</strong>
            </Text>
          )}
        </Center>
        <Box>
          {tabIndex === 0 && (
            <Button onClick={handleSaveAndFinishClick} isLoading={saving || uploading} mr={2}>
              Save and finish
            </Button>
          )}
          <Button type="submit" isLoading={saving || uploading} mr={2}>
            {tabIndex === 0 ? 'Next' : 'Done'}
          </Button>
        </Box>
      </Flex>
    </Box>
  );
};

export default UserForm;
