import React, { useEffect, useState } from 'react';
import { Card, CardBody, useDisclosure } from '@chakra-ui/react';

import api from '../../api';

import { AdminDataTable, Column } from '../../components/AdminDataTable';
import { useToastContext } from '../../ToastContext';
import { Utils } from '../../services';
import { ModalDialog } from '../../components/XModal';
import { DepartmentForm } from '../../components/DepartmentForm';
import { AddButton } from '../../components/AddButton';
import { useAuth } from '../../AuthContext';

export const AdminDepartments: React.FC = () => {
  const { user } = useAuth();
  const [data, setData]: any = useState(null);
  const [loading, setLoading] = useState(true);
  const [deleteing, setDeleteing] = useState(false);
  const [saving, setSaving] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const { isOpen: isAddNewOpen, onOpen: onAddNewOpen, onClose: onAddNewClose } = useDisclosure();
  const { isOpen: isEditOpen, onOpen: onEditOpen, onClose: onEditClose } = useDisclosure();

  const toast = useToastContext();

  const fetchData = async (page: number) => {
    try {
      setLoading(true);

      let organisationId = null;

      if (user?.organisation) {
        organisationId = user.organisation.id;
      }

      let response;

      if (organisationId) {
        response = await api.get(`/departments/organization/${organisationId}`, {
          params: {
            page: page,
          },
        });
      } else {
        response = await api.get(`/departments`, {
          params: {
            page: page,
          },
        });
      }

      setData(
        response.data.data.map(({ id, name, logoUrl, score }: any) => ({
          id,
          name,
          image: logoUrl,
          impactX: score.impactXScore,
        }))
      );
      setTotalPages(response?.data?.pagination?.totalPages);
      setLoading(false);
    } catch (error) {
      toast('An error occurred', Utils.formatErrorMessage(error), 'error');
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(currentPage);
  }, [currentPage]);

  const columns: Column[] = [
    {
      header: 'Department Name',
      accessor: 'name',
      align: 'left',
    },
    {
      header: 'impactX',
      accessor: 'impactX',
      align: 'center',
    },
  ];

  const deleteDepartment = async (id: string) => {
    setDeleteing(true);
    try {
      await api.delete(`/departments/${id}`);
      toast('Successfully deleted', 'Department successfully deleted', 'success');
      currentPage === 1 ? fetchData(1) : setCurrentPage(1);
    } catch (error) {
      toast('An error occurred', Utils.formatErrorMessage(error), 'error');
    }
    setDeleteing(false);
  };

  const addDepartment = async (deptData: any) => {
    setSaving(true);
    try {
      const { data } = await api.post(`/departments`, {
        ...Utils.cleanPayload(deptData),
        isAffiliate: false,
      });

      toast('Successfully created', 'Department successfully created', 'success');
      setSaving(false);
      return data;
    } catch (error: any) {
      setSaving(false);
      return error.response;
    }
  };

  const handleDone = () => {
    currentPage === 1 ? fetchData(1) : setCurrentPage(1);

    onAddNewClose();
    onEditClose();
  };

  const [toEdit, setToEdit] = useState(null);

  const editDepartment = async (data: any) => {
    setSaving(true);
    try {
      const { id, ...payload } = data;
      await api.patch(`/departments/${id}`, payload);
      toast('Successfully updated', 'Department successfully updated', 'success');
      // fetchData();
      currentPage === 1 ? fetchData(1) : setCurrentPage(1);
    } catch (error) {
      toast('An error occurred', Utils.formatErrorMessage(error), 'error');
    }

    setSaving(false);
  };

  const handleEditClick = async ({ id }: any) => {
    const response = await api.get(`/departments/${id}`);

    setToEdit(response.data.data);
    onEditOpen();
  };

  return (
    <>
      <ModalDialog title={`Add New Department`} isOpen={isAddNewOpen} onClose={onAddNewClose}>
        <DepartmentForm onSubmit={addDepartment} saving={saving} onDone={handleDone} />
      </ModalDialog>
      <ModalDialog title={`Edit Department`} isOpen={isEditOpen} onClose={onEditClose}>
        <DepartmentForm onSubmit={editDepartment} saving={saving} department={toEdit} onDone={handleDone} />
      </ModalDialog>
      <Card>
        <CardBody>
          <AddButton onClick={onAddNewOpen} />
          <AdminDataTable
            data={data}
            loading={loading}
            columns={columns}
            deleteing={deleteing}
            onDelete={deleteDepartment}
            onEditClick={handleEditClick}
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={setCurrentPage}
            isshowPagination={true}
            noAvatar
          ></AdminDataTable>
        </CardBody>
      </Card>
    </>
  );
};

export default AdminDepartments;
