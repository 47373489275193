import { Box } from '@chakra-ui/react';
import { Controller } from 'react-hook-form';

import { FieldValidationMessage } from './FieldValidationMessage';

const getError = (fieldName: string, errors: any) => {
  const keys = fieldName.split('.');
  const field = keys[0];
  let error = '';

  if (keys.length > 1) {
    error = errors[field]?.[keys[1]]?.message;
  } else {
    error = errors[field]?.message;
  }

  return error;
};

export const FormField = ({ fieldName, control, errors, render }: any) => {
  console.log(errors,fieldName,21)
  return (
    <Box>
      <Controller name={fieldName} control={control} defaultValue="" render={render} />
      <FieldValidationMessage>{getError(fieldName, errors)}</FieldValidationMessage>
    </Box>
  );
};
